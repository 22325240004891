.table {
	font-size: 12px;
	vertical-align: middle;
	
	& th {
		font-weight: 600;
		background-color: #E9EFF2;
		border-bottom: 2px solid #004680 !important;
	}
	
	& td {
		border-bottom: none;
	}
	
	& th, td {
		padding: 10px 10px;
	}
	
	& tr:nth-child(2n) td:not([colspan]) {
		background-color: #F2F2F2;
	}
}

//.table-mobile {
//	@media (max-width: $screen-sm - 1) {
//		//border-top: 1px solid $primary;
//
//		thead {
//			display: none;
//		}
//
//		td {
//			padding: .5rem;
//			display: flex;
//			font-size: 13px;
//
//			&::before {
//				content: attr(data-heading);
//				width: 90px;
//				min-width: 90px;
//				font-size: 13px;
//				margin-right: .5rem;
//			}
//		}
//	}
//}
