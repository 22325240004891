.file-icons {
	:not(:last-child) {
		margin-right: .25rem;
	}
}

.file-icon {
	display: inline-block;
	font-size: 51px;
	& a {
		text-decoration: none;
	}
	& a:hover {
		text-decoration: none;
	}
}

.socs {
	:not(:last-child) {
		margin-right: .75rem;
	}
}

a.soc {
	display: inline-block;
	width: 48px;
	height: 48px;
	border-radius: 5px;
	background: $secondary;
	text-align: center;
	font-size: 26px;
	line-height: 53px;
	color: $white;
	transition: background-color $transition;
	
	&:hover {
		background: $link-hover-color;
	}
	
	&_small {
		width: 24px;
		height: 24px;
		font-size: 13px;
		line-height: 26px;
	}
}
