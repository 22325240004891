.personal-managers {
	background-color: #F2F2F2;
	border-radius: 8px;
	padding: 20px;
}

.give-feedback {
	background-color: #F2F2F2;
	border-radius: 8px;
	padding: 20px;
	display: flex;
	align-items: center;
	
	&__img {
		margin-right: 20px;
	}
}

.page-head {
	width: 100%;
	padding: 10px 0;
	font-size: 20px;
	border-bottom: 2px solid #00629B;
	font-weight: 600;
	text-align: center;
	line-height: 120%;
	color: $primary
}

.page-nav {
	margin-bottom: 30px;
	
	&__list {
		width: 100%;
		margin: 0;
		display: flex;
		list-style: none;
		padding: 0;
		overflow: auto;
	}
	
	&__item {
		flex-grow: 1;
		margin: 0;
	}
	
	&__item:first-of-type &__link {
		border-radius: 10px 0 0 10px;
	}
	
	&__item:last-of-type &__link {
		border-radius: 0 10px 10px 0;
	}
	
	&__link {
		display: flex;
		justify-content: center;
		white-space: nowrap;
		width: 100%;
		padding: 6px 10px;
		background-color: #EAE6E0;
		color: #111;
	}
	
	&__link.active {
		position: relative;
		z-index: 2;
		background-color: #5FD1FF;
		color: #ffffff;
	}
	
	&__link.last {
		background-color: #EAE6E0;
		color: #999;
	}
	
	&__link.last:hover {
		color: $link-hover-color;
	}
	
	@media (max-width: $screen-lg - 1px) {
		&__list {
			flex-direction: column;
		}
		
		&__item:first-of-type &__link {
			border-radius: 10px 10px 0 0;
		}
		
		&__item:last-of-type &__link {
			border-radius: 0 0 10px 10px;
		}
	}
}

.grid-table {
	display: grid;
	
	&_col-2 {
		grid-template-columns: auto 1fr;
	}
	
	&__item:nth-child(2n) {
		margin-left: 14px;
		text-align: left;
	}
}

.user-list {
	&__item {
		padding: 20px;
		border: 2px solid #E9EFF2;
		border-radius: 8px;
	}
}

.search-filter {
	display: flex;
	margin: 0 -4px;
	flex-wrap: wrap;
	margin-bottom: -8px;
	
	&__item {
		padding: 0 4px;
		margin-bottom: 8px;
	}
	
	&__link {
		display: block;
		padding: 10px;
		border-radius: 8px;
		background-color: #E9EFF2;
		font-size: 12px;
	}
	
	&__link.active {
		background-color: #5FD1FF;
		color: #ffffff;
	}
}

.applications-tag {
	background: #82D0F5;
	border-radius: 8px;
	padding: 6px 10px;
}

.index-info {
	padding: 30px;
	background-color: #F2F2F2;
	border-radius: 8px;
	height: 100%;
}

.index-promo {
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background-color: #F2F2F2;
	padding: 20px 20px 20px 20px;
	text-align: center;
	overflow: hidden;
	
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	&.splide__slide {
		padding-bottom: 0;
	}
	
	& .splide__pagination {
		margin-top: 20px;
	}
	
	&__slider {
		border-radius: 8px;
		background-color: #F2F2F2;
		padding-bottom: 20px;
		text-align: center;
		overflow: hidden;
		height: 100%;
	}
	
	&__slider .splide__pagination {
		margin-top: 20px;
	}
	
	&__img-wrap {
		position: relative;
		width: calc(100% + 40px);
		margin-left: -20px;
		margin-top: -20px;
		margin-bottom: 10px;
		border-radius: 8px;
		overflow: hidden;
		padding-bottom: 70%;
	}
	
	@media (max-width: $screen-lg - 1px) and (min-width: $screen-md) {
		flex-direction: row;
		text-align: left;
		&__img-wrap {
			width: 50%;
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 30px;
			padding-bottom: 40%;
		}
	}
}

.login-page {
	display: flex;
	min-height: 100%;
	
	&__content {
		padding: 40px 15px;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	&__img-wrap {
		position: relative;
		width: 50%;
	}
	
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	&__img-info {
		position: absolute;
		bottom: 80px;
		right: 60px;
	}
	
	&__inner {
		width: 500px;
	}
	
	@media (max-width: $screen-lg - 1px) {
		display: block;
		&__content {
			width: 100%;
		}
		
		&__img-wrap {
			display: none;
		}
	}
}

.login-inner {
	width: 100%;
	
	&__nav {
		width: 100%;
		display: flex;
		margin-top: 20px;
	}
	
	&__nav-item {
		padding: 6px 10px;
		flex-grow: 1;
		text-align: center;
		border-bottom: 2px solid #AFCBDF;
		color: #AFCBDF;
	}
	
	&__nav-item.active {
		color: $primary;
		border-bottom: 2px solid $primary;
	}
}

.about-slider {
	&__item {
		padding: 20px;
		border: 2px solid #004680;
		border-radius: 8px;
		cursor: pointer;
		box-sizing: border-box;
	}
	
	&__item:not(:last-of-type) {
		margin-bottom: 10px;
	}
	
	&__item.active {
		padding: 18px;
		border: 4px solid #F9E547;
	}
	
	&__item:not(.active):hover {
		border-color: $link-hover-color;
	}
}

.about-slide {
	max-width: 100%;
	cursor: grab;
	
	&__img-wrap {
		width: 100%;
		padding-bottom: 62%;
		position: relative;
	}
	
	&__img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}
	
	&__title-head {
		margin-top: 20px;
		text-align: center;
		color: $secondary;
		font-size: 12px;
	}
	
	&__info {
		margin-top: 20px;
		display: none;
	}
	
	@media (max-width: $screen-lg - 1) {
		&__title-head {
			display: none;
		}
		&__info {
			display: block;
		}
	}
}

.about-stage {
	display: flex;
	align-items: flex-start;
	
	&__img {
		min-width: 44px;
		width: 44px;
		margin-right: 20px;
		object-fit: contain;
	}
}

.manager-item {
	display: flex;
	align-items: flex-start;
	
	&__img {
		width: 130px;
		object-fit: contain;
		margin-right: 30px;
	}
	
	@media (max-width: $screen-sm - 1) {
		flex-direction: column;
		&__body {
			margin-top: 10px;
		}
	}
}

.login-alert {
	background: rgba(255, 215, 0, 0.1);
	border: 1px solid #FFD700;
	box-sizing: border-box;
	border-radius: 10px;
}

.table-sort {
	transition: color;
	cursor: pointer;
	
	&:hover {
		color: $link-hover-color;
	}
	
	&_up:after {
		margin-left: 8px;
		font-size: 8px;
		@include icon-font;
		content: $icon-arrow-small;
		vertical-align: center;
	}
	
	&_down:after {
		display: inline-block;
		transform: rotate(180deg) translateY(1px);
		margin-left: 8px;
		font-size: 8px;
		@include icon-font;
		content: $icon-arrow-small;
		vertical-align: center;
	}
}