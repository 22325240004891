.dropDown {
    position: absolute;
    z-index: 99999;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);
    margin-top: -15px;
}

.dropDown__item {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    cursor: pointer;
}

.dropDown__item:hover {
    background-color: rgba(0,0,0,0.1);
}
