$icomoon-font-family: "icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-tel: "\e918";
$icon-arrow-small: "\e917";
$icon-download: "\e900";
$icon-user: "\e901";
$icon-mail: "\e902";
$icon-print: "\e903";
$icon-lock: "\e904";
$icon-eye: "\e905";
$icon-eye-closed: "\e906";
$icon-file-ppt: "\e907";
$icon-file-xlsx: "\e908";
$icon-file-docx: "\e909";
$icon-file-pdf: "\e90a";
$icon-file-xls: "\e90c";
$icon-file-doc: "\e90d";
$icon-file-zip: "\e90e";
$icon-file-rar: "\e90f";
$icon-file-txt: "\e910";
$icon-file-jpg: "\e911";
$icon-file-mp4: "\e912";
$icon-file-png: "\e913";
$icon-file-gif: "\e914";
$icon-file-mp3: "\e915";
$icon-place: "\e916";
$icon-trash: "\e91c";
$icon-close: "\e91d";
$icon-external: "\e91e";
$icon-arrow-back: "\e91f";
$icon-calendar: "\e920";
$icon-file: "\e921";
$icon-danger: "\e922";
$icon-dropdown: "\e923";
$icon-info: "\e924";
$icon-success: "\e925";
$icon-soc-dropbox: "\e92c";
$icon-soc-facebook: "\e92d";
$icon-soc-gplus: "\e92e";
$icon-soc-instagram: "\e92f";
$icon-soc-linkedin: "\e930";
$icon-soc-mailru: "\e931";
$icon-soc-ok: "\e932";
$icon-soc-pinterest: "\e933";
$icon-soc-skype: "\e934";
$icon-soc-telegram: "\e935";
$icon-soc-twitter: "\e936";
$icon-soc-viber: "\e937";
$icon-soc-vimeo: "\e938";
$icon-soc-vk: "\e939";
$icon-soc-whatsapp: "\e93a";
$icon-soc-yadisk: "\e93b";
$icon-soc-yandex: "\e93c";
$icon-soc-youtube: "\e93d";
$icon-loupe: "\e90b";

