@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?e3a5vx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?e3a5vx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?e3a5vx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file {
	&:before {
		content: $icon-file;
	}
}

.icon-tel {
  &:before {
    content: $icon-tel; 
  }
}
.icon-arrow-small {
  &:before {
    content: $icon-arrow-small; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-closed {
  &:before {
    content: $icon-eye-closed; 
  }
}
.icon-file-ppt {
  &:before {
    content: $icon-file-ppt; 
  }
}
.icon-file-xlsx {
  &:before {
    content: $icon-file-xlsx; 
  }
}
.icon-file-docx {
  &:before {
    content: $icon-file-docx; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}
.icon-file-xls {
  &:before {
    content: $icon-file-xls; 
  }
}
.icon-file-doc {
  &:before {
    content: $icon-file-doc; 
  }
}
.icon-file-zip {
  &:before {
    content: $icon-file-zip; 
  }
}
.icon-file-rar {
  &:before {
    content: $icon-file-rar; 
  }
}
.icon-file-txt {
  &:before {
    content: $icon-file-txt; 
  }
}
.icon-file-jpg {
  &:before {
    content: $icon-file-jpg; 
  }
}
.icon-file-mp4 {
  &:before {
    content: $icon-file-mp4; 
  }
}
.icon-file-png {
  &:before {
    content: $icon-file-png; 
  }
}
.icon-file-gif {
  &:before {
    content: $icon-file-gif; 
  }
}
.icon-file-mp3 {
  &:before {
    content: $icon-file-mp3; 
  }
}
.icon-place {
  &:before {
    content: $icon-place; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-external {
  &:before {
    content: $icon-external; 
  }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-danger {
  &:before {
    content: $icon-danger; 
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-soc-dropbox {
  &:before {
    content: $icon-soc-dropbox; 
  }
}
.icon-soc-facebook {
  &:before {
    content: $icon-soc-facebook; 
  }
}
.icon-soc-gplus {
  &:before {
    content: $icon-soc-gplus; 
  }
}
.icon-soc-instagram {
  &:before {
    content: $icon-soc-instagram; 
  }
}
.icon-soc-linkedin {
  &:before {
    content: $icon-soc-linkedin; 
  }
}
.icon-soc-mailru {
  &:before {
    content: $icon-soc-mailru; 
  }
}
.icon-soc-ok {
  &:before {
    content: $icon-soc-ok; 
  }
}
.icon-soc-pinterest {
  &:before {
    content: $icon-soc-pinterest; 
  }
}
.icon-soc-skype {
  &:before {
    content: $icon-soc-skype; 
  }
}
.icon-soc-telegram {
  &:before {
    content: $icon-soc-telegram; 
  }
}
.icon-soc-twitter {
  &:before {
    content: $icon-soc-twitter; 
  }
}
.icon-soc-viber {
  &:before {
    content: $icon-soc-viber; 
  }
}
.icon-soc-vimeo {
  &:before {
    content: $icon-soc-vimeo; 
  }
}
.icon-soc-vk {
  &:before {
    content: $icon-soc-vk; 
  }
}
.icon-soc-whatsapp {
  &:before {
    content: $icon-soc-whatsapp; 
  }
}
.icon-soc-yadisk {
  &:before {
    content: $icon-soc-yadisk; 
  }
}
.icon-soc-yandex {
  &:before {
    content: $icon-soc-yandex; 
  }
}
.icon-soc-youtube {
  &:before {
    content: $icon-soc-youtube; 
  }
}
.icon-loupe {
  &:before {
    content: $icon-loupe; 
  }
}

