.tabs-base {
	padding-bottom: 0;
	margin-bottom: 1rem;
	border-bottom: 2px solid #004680;
	.nav-item {
		margin-bottom: -1px;
		margin-right: -1px;
	}

	.nav-link {
		border: 2px solid #004680;
		border-radius: 8px 8px 0 0;
	}
	
	.nav-link:hover{
		border: 2px solid $link-hover-color;
	}
	
	.nav-link.active {
		border: 2px solid #004680;
		border-radius: 8px 8px 0 0;
		background: #004680;
		color: #ffffff;
	}
}
