// icon font
@mixin icon-font {
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// custom scrollbar for webkit
@mixin custom-scroll {
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		margin: 10px 0;
		background: linear-gradient(to right, transparent 3px, #F1F1F1 3px, #F1F1F1 5px, transparent 5px);
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $secondary;
		border-radius: 50px;
	}
}

@mixin box-shadow {
	box-shadow: 0 6px 20px rgba(0, 0, 0, .2);
}
