.accordion-item {
	margin-bottom: 10px;
	border-radius: 5px;
	border: none;
	background: #E9EFF2;
	overflow: hidden;
	transition: background-color;
	&.active {
		background-color: #ffffff;
		box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
	}
}

.accordion-button {
	padding: 20px 10px;
	font-size: 16px;
	font-weight: 700;
	color: $primary;
	background-color: transparent;
}

.accordion-button:not(.collapsed) {
	color: #0073D3;
	background-color: transparent;
	border-bottom: none;
	box-shadow: none;
}