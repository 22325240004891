.form-control {
	height: auto;
	border-color: #E9EFF2;
	padding: 10px;
	line-height: 1;
	font-size: 18px;
	background-color: #E9EFF2;
	border-radius: 8px;

	&:hover {
		border-color: $link-hover-color;
	}

	&:focus {
		box-shadow: none;
		border-color: $link-hover-color;
		background-color: #E9EFF2;
	}

	&:disabled {
		border-color: #E5E5E5;
		background-color: #E9EFF2;

		&::placeholder {
			color: #E5E5E5;
		}
	}

	&:invalid {
		outline: none; // for ie
	}

	&::placeholder {
		color: #BFBFBF;
		line-height: 1.4; // for iPad
	}
}

.has-error {
	label {
		color: $alert-danger;
	}

	.form-control {
		border-color: $alert-danger;
	}
}

input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

input::-ms-clear {
	display: none;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
	appearance: none;
}

select.selectized {
	display: block !important;
	position: absolute;
	visibility: hidden;
}

button:focus {
	outline: none;
}

input[type="checkbox"],
input[type="radio"] {
	visibility: hidden;
	position: absolute;

	+ span {
		display: inline-block;
		font-size: 1rem;
		font-weight: normal;
		padding-left: 22px;
		user-select: none;
		position: relative;
	}
}

.custom-file {
	height: 50px;
	margin-bottom: 0;
	max-width: 100%;
	
	&__input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	
	&__label {
		font-size: 18px;
		font-weight: bold;
		color: #111111;
		background-color: #fff;
		cursor: pointer;
		transition: background-color 200ms;
		outline: none;
		border: 1px solid #999999;
		width: 100%;
		display: flex;
		border-radius: 8px;
		margin-bottom: 0;
		line-height: 120%;
		height: 50px;
		
		& * {
			pointer-events: none;
		}
		
		& span {
			font-weight: 400;
			flex-grow: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 14px 10px 10px 10px;
			font-size: 18px;
			
			&.placeholder {
				color: #BFBFBF;
			}
		}
		
		& strong {
			font-size: 16px;
			font-weight: 600;
			display: flex;
			align-items: center;
			white-space: nowrap;
			border-radius: 8px;
			padding: 0 20px;
			background-color: #F9E547;
			color: #111;
			margin-top: -1px;
			margin-right: -1px;
			height: 50px;
			transition: background-color 200ms;
			border: 1px solid #F9E547;
		}
	}
	
	&__input:focus + &__label strong,
	&__input + &__label:hover strong {
		color: #111111;
		border-color: #82D0F5;
		background-color: #82D0F5;
	}
}

$checkbox-size: 16px;

input[type="checkbox"] {
	+ span {
		&::before {
			content: '';
			width: $checkbox-size;
			height: $checkbox-size;
			border: 1px solid $secondary;
			border-radius: 3px;
			background: $white;
			position: absolute;
			top: 3px;
			left: 0;
		}
		
		&.small::before {
			top: 1px;
		}
	}
	
	&:checked {
		+ span {
			&::before {
				border-color: #5FD1FF;
				background: #5FD1FF;
			}
			
			&::after {
				content: '';
				width: 10px;
				height: 6px;
				border-style: solid;
				border-width: 0 0 2px 2px;
				border-color: $white;
				transform: rotate(-45deg);
				position: absolute;
				top: 7px;
				left: 3px;
			}
			
			&.small::after {
				top: 5px;
			}
		}
		
		&:disabled {
			+ span {
				&::before {
					background: #E5E5E5;
				}
			}
		}
	}
	
	&:disabled {
		+ span {
			color: #E5E5E5;
			
			&::before {
				border-color: #E5E5E5;
			}
		}
	}
}

$radio-size: 16px;

input[type="radio"] {
	+ span {
		&::before {
			content: '';
			width: $radio-size;
			height: $radio-size;
			border: 1px solid $secondary;
			border-radius: 50%;
			background: $white;
			position: absolute;
			top: 3px;
			left: 0;
		}
	}
	
	&:checked {
		+ span {
			&::after {
				content: '';
				width: $radio-size - 6;
				height: $radio-size - 6;
				border-radius: 50%;
				//background: $primary;
				position: absolute;
				top: 6px;
				left: 3px;
			}
			
			&::before {
				border: 4px solid #5FD1FF;
			}
		}
		
		&:disabled {
			+ span {
				&::after {
					background: #E5E5E5;
				}
			}
		}
	}
	
	&:disabled {
		+ span {
			color: #E5E5E5;
			
			&::before {
				border-color: #E5E5E5;
			}
		}
	}
}

.counter {
	display: inline-block;
	width: 95px;
	position: relative;
	user-select: none;

	&-input {
		padding: 7px 30px;
		text-align: center;
	}

	&-minus,
	&-plus {
		display: inline-block;
		width: 30px;
		height: 100%;
		cursor: pointer;
		position: absolute;
		top: 0;

		&:hover {
			~ .counter-input {
				border-color: $primary;
			}
		}

		&::before {
			display: inline-block;
			content: '';
			width: 14px;
			height: 2px;
			background: $primary;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&-minus {
		left: 0;
	}

	&-plus {
		right: 0;

		&::after {
			display: inline-block;
			content: '';
			width: 14px;
			height: 2px;
			background: $primary;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
}

.custom-group {
	position: relative;

	&-prepend {
		.form-control {
			padding-left: 2.5rem;
		}

		.custom-group-icon {
			left: 1px;
		}
	}

	&-append {
		.form-control {
			padding-right: 2.5rem;
		}

		.custom-group-icon {
			right: 1px;
		}
	}

	&-icon {
		height: calc(100% - 2px);
		width: 2.5rem;
		font-size: 18px;
		color: $secondary;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}
.form-floating {
	overflow: hidden;
}
.form-floating label {
	white-space: nowrap;
	text-overflow: ellipsis;
	min-width: 0;
	width: 100%;
	overflow: hidden;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
	opacity: 1;
	font-size: 12px;
	transform: translateY(-8px);
	transition: all 200ms;
}

.form-floating > .form-control:not([type="date"]):focus, .form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 20px;
	padding-bottom: 6px;
}

.form-floating > .form-control[type="date"]:focus ~ label, .form-floating > .form-control[type="date"]:placeholder-shown {
	opacity: 1;
	font-size: 12px;
	transform: translateY(-8px);
	transition: all 200ms;
}

.form-floating > .form-control[type="date"]:focus {
	padding-top: 20px;
	padding-bottom: 6px;
}

.form-floating > .form-control {
	padding: 10px;
}

.form-floating > label {
	padding: 10px;
}

.form-floating > .form-control, .form-floating > .form-select {
	height: 50px;
}

.form-control-base {
	height: 36px;
	font-size: 14px;
}
.label-bold {
	margin-bottom: 4px;
	font-weight: 600;
}

select, option {
	-webkit-appearance: none;
}
input[type='date'], input[type='time'] {
	-webkit-appearance: none;
}

.form-select {
	background-size: 12px 6px;
	background-position: right 18px center;
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.79289 0H1.20711C0.761654 0 0.53857 0.53857 0.853553 0.853553L4.64645 4.64645C4.84171 4.84171 5.15829 4.84171 5.35355 4.64645L9.14645 0.853553C9.46143 0.538571 9.23835 0 8.79289 0Z' fill='%23111111'/%3E%3C/svg%3E");
}

.form-select:focus {
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.20711 5L8.79289 5C9.23835 5 9.46143 4.46143 9.14645 4.14645L5.35355 0.353553C5.15829 0.158291 4.84171 0.158291 4.64645 0.353553L0.853554 4.14645C0.538571 4.46143 0.761654 5 1.20711 5Z' fill='%23111111'/%3E%3C/svg%3E%0A");
}