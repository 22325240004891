// sticky footer
html {
	height: 100%;
}

body {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.main {
	flex-grow: 1;
	display: flex;
}

.content-page {
	width: calc(100% - 320px);
	display: flex;
	height: 100%;
	position: relative;
}

.content-main {
	flex-grow: 1;
	height: 100%;
	padding: 0 40px 40px 40px;
}

.content-promo {
	min-width: 520px;
	width: 520px;
	height: 100%;
	background-color: #C4C4C4;
	padding: 20px;
}

.test {
	background-color: #5FD1FF;
	height: 100%;
	width: 100%;
}

.sidebar {
	width: 320px;
	max-width: 100vw;
	background-color: #004680;
	padding: 0 20px;
	
	& .header-lk {
		display: none;
	}
	
	&__backdrop {
		display: none;
	}
}

.header {
	width: 100%;
	display: flex;
}

.header-logo {
	background-color: #004680;
	padding: 20px;
	width: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	&__img {
		height: 40px;
	}
}

.header-inner {
	flex-grow: 1;
	background-color: #F2F2F2;
	display: flex;
	padding: 20px 0;
}

.header-lk {
	min-width: 520px;
	width: 520px;
	padding: 0 20px;
	
	&__title {
		text-align: right;
	}
	
	& .form-control {
		border-color: transparent;
		background-color: #ffffff;
	}
	
	& .btn-base {
		background-color: #ffffff;
	}
}

.header-alert {
	display: flex;
	flex-grow: 1;
	padding: 0 40px;
	white-space: nowrap;
	width: 0;
	
	&__status {
		margin-right: 40px;
	}
	
	&__info {
		min-width: 0;
		flex: 1;
	}
	
	&__info-title {
		min-width: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
}

.menu-nav {
	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	
	&__item {
		margin-bottom: 10px;
	}
	
	&__link {
		color: #E9EFF2;
		
		&:hover {
			color: #ffffff;
		}
	}
	
	&__item &__list {
		margin-top: 10px;
		margin-left: 16px;
	}
}

@media (max-width: $screen-xxl - 1px) {
	.content-page {
		flex-direction: column;
	}
	.content-promo {
		width: 100%;
		height: auto;
		min-height: 100px;
		min-width: auto;
	}
}

@media (max-width: $screen-xl - 1px) {
	.sidebar {
		display: none;
	}
	.content-page {
		width: 100%;
		padding: 0 40px;
	}
	.content-main {
		padding: 0 0 40px 0;
	}
	.header-alert {
		display: none;
	}
	.header-lk {
		display: none;
	}
	.header {
		min-height: 80px;
	}
	.header-inner {
		background-color: #004680;
	}
	.header-logo {
		width: auto;
	}
}

@media (max-width: $screen-md - 1px) {
	.content-page {
		padding: 0 15px;
	}
}

.menu-bar {
	display: inline-block;
	width: 20px;
	vertical-align: -2px;
	
	&__stick {
		display: block;
		height: 2px;
		border-radius: 2px;
		background: $primary;
		transition: transform $transition;
		
		&:not(:last-of-type) {
			margin-bottom: 5px;
		}
	}
	
	&.active {
		.menu-bar__stick {
			&:nth-child(1) {
				transform: translateY(7px) rotate(-45deg);
			}
			
			&:nth-child(2) {
				opacity: 0;
			}
			
			&:nth-child(3) {
				transform: translateY(-7px) rotate(45deg);
			}
		}
	}
}

.menu-bar {
	display: inline-block;
	width: 40px;
	vertical-align: -2px;
	align-self: center;
	margin-right: 15px;
	
	&__stick {
		display: block;
		height: 3px;
		border-radius: 2px;
		background: #fff;
		transition: transform $transition;
		
		&:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}
	
	&.active {
		.menu-bar__stick {
			&:nth-child(1) {
				transform: translateY(9px) rotate(-45deg);
			}
			
			&:nth-child(2) {
				opacity: 0;
			}
			
			&:nth-child(3) {
				transform: translateY(-8px) rotate(45deg);
			}
		}
	}
}

@media (max-width: $screen-xl - 1px) {
	
	.mobile-menu-open {
		overflow: hidden;
		
		& .sidebar {
			display: block;
			position: fixed;
			right: 0;
			top: 80px;
			bottom: 0;
			z-index: 50;
			padding-bottom: 100px;
			overflow: auto;
		}
		
		& .sidebar__backdrop {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			cursor: pointer;
			z-index: 49;
		}
		
		& .sidebar .header-lk {
			display: block;
			padding: 0;
			width: 100%;
			min-width: auto;
			color: #ffffff;
			margin-bottom: 20px;
			text-align: left;
		}
		
		& .sidebar .header-lk__title {
			text-align: left;
		}
		
		& .menu-bar__stick {
			&:nth-child(1) {
				transform: translateY(13px) rotate(-45deg);
			}
			
			&:nth-child(2) {
				opacity: 0;
			}
			
			&:nth-child(3) {
				transform: translateY(-8px) rotate(45deg);
			}
		}
	}
}