.head_info {
    font-size: 12px;
}

.download {
    cursor: pointer;
}

.docNotes {
    position: relative;
    /*bottom: 10px;*/
    /*left: -65px;*/
    max-height: 300px;
    z-index: 100;
    overflow: scroll;
    overflow-x: hidden;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 0 7px rgba(0,0,0,0.5);;
    background-color: #ffffff;

}

.docNotes::after {
    /* content: '';
    position: absolute;
    left: 20px;
    bottom: -50px;
    z-index: 104;
    border: 10px solid transparent;
    border-right: 10px solid #000; */
}

.checkmarkError {
    z-index: 1;
    background-color: transparent;
}

.error {
    background-color: red;
}

.link {
    margin-top: 5px;
    margin-left: 30px;
    opacity: 1;
}

.linkNone {
    display: none;
}

.linkOpacity {
    opacity: 0.5;
}

.errorDoc {
    margin-bottom: 5px;
    color: red;
}
