.modal {
	&-content {
		border: none;
		border-radius: $border-radius;

		@include box-shadow;
	}

	&-header {
		border-bottom: none;
		border-radius: $border-radius $border-radius 0 0;
		padding: 1.5rem;

		.close {
			padding: .5rem;
		}
	}

	&-title {
		margin-top: 0;
	}

	&-body {
		padding: 0 1.5rem 1rem;
	}

	&-footer {
		border-top: none;
		border-radius: 0 0 $border-radius $border-radius;
		padding: 0 1.5rem 1.5rem;
		justify-content: flex-start;
	}
}

.close {
	font-size: 16px;
	color: $primary;
	opacity: 1;

	&:not(:disabled):not(.disabled):focus,
	&:not(:disabled):not(.disabled):hover {
		color: $primary;
		opacity: 1;
	}
}

.modal-backdrop.show {
	opacity: .75;
}

//.stub-image {
//	background: #EBEBEB;
//	padding-bottom: 100%;
//	border-radius: 5px;
//}
