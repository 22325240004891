.pagination {
	margin: 0;
	font-weight: 700;
	.page-item {
		margin-bottom: 0;
	}

	.page-link {
		&:focus {
			box-shadow: none;
		}
	}

	&-button {}

	&-number {
		max-width: 120px;
	}
}
