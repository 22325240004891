// grid breakpoints
$screen-xs: 0;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1344px;
$screen-xxl: 1864px;

// container max widths
$container-sm: 540px;
$container-md: 720px;
$container-lg: 994px;
$container-xl: 1284px;
$container-xxl: 1804px;


// bootstrap overrides

//$enable-rounded: false;

$body-color: #111;

$font-family-sans-serif: 'Open Sans', sans-serif;

$primary: #004680;
$secondary: #999;
$success: #28A745;

$link-hover-color: #82D0F5;
$link-hover-decoration: none;

//$h1-font-size: 25px;
//$h2-font-size: 20px;
//$h3-font-size: 16px;
//$h4-font-size: 16px;
//$h5-font-size: 16px;
//$h6-font-size: 16px;

$border-radius: 5px;

$grid-breakpoints: (
	xs: $screen-xs,
	sm: $screen-sm,
	md: $screen-md,
	lg: $screen-lg,
	xl: $screen-xl,
	xxl: $screen-xxl
);

$container-max-widths: (
	sm: $container-sm,
	md: $container-md,
	lg: $container-lg,
	xl: $container-xl,
	xxl: $container-xxl
);


// icons
$icomoon-font-path: './fonts/icons';
