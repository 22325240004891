.btn {
	white-space: normal;
	font-weight: 700;
	color: #111111;
	border-radius: 8px;

	&:focus {
		box-shadow: none;
	}

	//[class^="icon-"], [class*=" icon-"] {
	//	font-size: 1.2em;
	//	vertical-align: -3px;
	//}

	&-base {
		background: #F9E547;
		padding: 6.5px 20px;
		color: #111111;

		&:not(:disabled):hover {
			background: #82D0F5;
			color: #111111;

			&:active {
				background: #0073D3;
			}
		}

		&:disabled,
		&.disabled {
			background: #EAE6E0;
			color: $secondary;
		}
	}

	&-large {
		padding: 9px 20px;
		font-size: 20px;
	}

	&-small {
		padding: 3px 12px;
		font-size: 13px;
		font-weight: 400;
	}

	&-ghost {
		border: 2px solid #F9E547;
		padding: 5.5px 20px;
		color: #111;

		&:not(:disabled):hover {
			border-color: $link-hover-color;
			color: $link-hover-color;

			&:active {
				border-color: #0073D3;
				color: #0073D3;
			}
		}

		&:disabled,
		&.disabled {
			border-color: #EAE6E0;
			color: #EAE6E0;
		}

		&.btn-large {
			padding: 8px 20px;
		}

		&.btn-small {
			padding: 2px 12px;
			font-size: 13px;
			font-weight: 400;
		}
	}
}
