.table_h {
    opacity: 0.5;
}

.table_h div {
    cursor: pointer;
}

.opacityBlock {
    opacity: 1;
}

.opacityNo {
    opacity: 0.5;
}

.table_h img {
    margin-left: 10px;
    opacity: 0.5;
}

.search {
    cursor: pointer;
    margin: 0 5px -4px 0;
}
