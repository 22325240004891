.alert {
	// clearing bootstrap alert
	border: none;
	background: none;
	padding: 0;
	color: $body-color;

	padding-left: 2rem;
	margin-bottom: 1rem;
	position: relative;

	&::before {
		font-size: 22px;
		position: absolute;
		left: 0;
	}

	&-success {
		&::before {
			@include icon-font;
			content: $icon-success;
			color: $alert-success;
		}
	}

	&-info {
		&::before {
			@include icon-font;
			content: $icon-info;
			color: $alert-info;
		}
	}

	&-danger {
		&::before {
			@include icon-font;
			content: $icon-danger;
			color: $alert-danger;
		}
	}
}
