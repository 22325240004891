// bootstrap
@import 'custom';
@import '~bootstrap/scss/bootstrap';
// vendors
@import "~@splidejs/splide/dist/css/splide-core.min.css";
@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'common';

// tech layout
@import 'tech/forms';
@import 'tech/buttons';
@import 'tech/alerts';
@import 'tech/tabs';
@import 'tech/table';
@import 'tech/pagination';
@import 'tech/files-n-socs';
@import 'tech/modal-n-stubs';
@import 'tech/reaction';
@import 'tech/tags';
@import 'tech/accordion';

@import 'layout';

// bem entities
@import 'bems';
