#root {
    height: 100%;
    min-height: 100vh;
}

.login-page {
    min-height: 100vh !important;
}

.btn-slider {
    border-radius: 4px;
    padding: 2px;
    min-width: 15px;
}



.btn-slider-ab {
    background-color: #999999;
    height: 1px;
    width: 25px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 2px;
    border: none;
    padding: 0.1rem 0.1rem;
}

.btn-slider-active {
    background-color: #82D0F5;
}



.bUl {
    list-style-type: square;
}

.header-alert__info {

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-open {
    background: rgb(0, 0, 0, 0.6);
    position: fixed;
    z-index: 3;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
}

.load-center {
    margin-top: 35px;
    margin-left: 35px;
}

.load {
    display: block;
    margin: 0 auto;
    width: 17px;
    height: 17px;
    border: solid 1px transparent;
    border-top-color: #000;
    border-left-color: #000;
    border-radius: 50%;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

.content-promo {
    /* min-width: 520px;
    width: 520px; */
    height: 100%;
    background-color: #fff;
    padding: 20px;
}

#my_file {
    display: none;
}

body {
    min-height: 100vh !important;
}

main {
    min-height: 100vh !important;
}


.main {
    height: 100%;
}

.page-nav__link {
    border: 0;
}

.page-nav__link:disabled {
    background-color: #EAE6E0;
    color: #999;
}

.block-yellow {
    border-radius: 8px;
    background-color: #f9e548;
}

.btn-ref {
    color: #004680;
    transition: color 0.2s ease-in-out;
    outline: none;
    font-weight: lighter;
    padding: 0;
}

.btn-ref:hover {
    color: #82D0F5;
    transition: color 0.2s ease-in-out;
    outline: none;
    font-weight: lighter;
    padding: 0;
}

.btn-ref-link {
    color: #004680;
    transition: color 0.2s ease-in-out;
    outline: none;
    font-weight: lighter;
    padding: 0;
    text-decoration: underline
}

.btn-ref-link:hover {
    color: #82D0F5;
    transition: color 0.2s ease-in-out;
    outline: none;
    font-weight: lighter;
    padding: 0;
    text-decoration: underline
}

.btn-black {
    display: block;
    width: 14px;
    height: 14px;
    border: solid 1px transparent;
    border-top-color: #000;
    border-left-color: #000;
    border-radius: 50%;
    margin: 8px auto;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

.btn-black-base {
    display: block;
    width: 17px;
    height: 17px;
    border: solid 2px transparent;
    border-top-color: #000;
    border-left-color: #000;
    border-radius: 50%;
    margin: 2px auto;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

.btn-white {
    display: block;
    width: 14px;
    height: 14px;
    border: solid 1px transparent;
    border-top-color: #fff;
    border-left-color: #fff;
    border-radius: 50%;
    margin: 8px auto;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes pace-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes pace-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes pace-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.alert-success {
    border-radius: 10px;

    border: 1px solid #4eb725;
}



.qrNopaper {
    height: 150px;
}

.newBox .num {
    display: inline-block;
    border: 2px solid #F9E547;
    margin-bottom: 15px;
    background: #fff;
    padding: 8px;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    font-size: 16px;
    text-align: center;
}

.newBox .numTitle {
    padding-top: 10px;
}