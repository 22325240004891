html {
	font-size: 14px;
}

h1, .h1, {
	color: $primary;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-weight: 700;
}

//
//h5, .h5,
//h6, .h6 {
//	margin-bottom: .25rem;
//}
//
//@media (min-width: $screen-sm) {
//	h1, .h1 {
//		font-size: 47px;
//	}
//
//	h2, .h2 {
//		font-size: 38px;
//	}
//
//	h3, .h3 {
//		font-size: 30px;
//	}
//
//	h4, .h4 {
//		font-size: 25px;
//	}
//
//	h5, .h5 {
//		font-size: 20px;
//	}
//}

h1, .h1 {
	font-size: 20px;
}

h2, .h2 {
	font-size: 18px;
}

h3, .h3 {
	font-size: 27px;
}

h4, .h4 {
	font-size: 20px;
}

h5, .h5 {
	font-size: 20px;
	font-weight: 400;
}

figcaption {
	font-size: 13px;
	color: $secondary;
}

a {
	color: $primary;
	transition: color $transition;
	outline: none;
	text-decoration: none;
	
	&:hover {
		text-decoration: none;
	}
}

a:not([class]) {
	text-decoration: underline;
	
	&:hover {
		text-decoration: underline;
	}
}

.fake {
	border-bottom: 1px dotted rgba($primary, .5);
	transition: color $transition, border-bottom-color $transition;
	
	&:hover {
		border-bottom-color: $link-hover-color;
	}
}

a.fake {
	text-decoration: none;
	
	&:hover {
		text-decoration: none;
	}
}

ul:not([class]) {
	list-style: none;
	padding-left: .9rem;
	position: relative;
	
	li {
		&::before {
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #82D0F5;
			position: absolute;
			left: 0;
			margin-top: .55em;
		}
	}
}


ul.row {
	list-style: none;
	padding: 0;
	
	li {
		position: relative;
		padding-left: calc((var(--bs-gutter-x) * .5) + 16px);
		
		&::before {
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: #82D0F5;
			position: absolute;
			left: calc(var(--bs-gutter-x) * .5);
			margin-top: .55em;
		}
	}
}

ol:not([class]) {
	list-style: none;
	padding: 0;
	counter-reset: list-numeric;
	
	li {
		&::before {
			counter-increment: list-numeric;
			content: counter(list-numeric);
			font-weight: bold;
			margin-right: .5rem;
		}
	}
	
	ol {
		margin-top: 1rem;
		padding-left: 1rem;
		counter-reset: list-numeric-inner;
		
		li {
			&::before {
				counter-increment: list-numeric-inner;
				content: counter(list-numeric) '.' counter(list-numeric-inner);
			}
		}
	}
}


li {
	margin-bottom: 1rem;
}

label {
	cursor: pointer;
}

.form-floating label {
	font-weight: 400;
	margin-bottom: 0;
	font-size: 18px;
	color: $secondary;
}

//b, strong {
//	font-weight: bold;
//}

.light-text {
	font-size: 17px;
	font-weight: $font-weight-light;
	margin-bottom: 1.5rem;
}

.list-wrapper {
	margin-left: -.8rem;
}

.img-bg {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	
	&::before {
		display: block;
		content: '';
		padding-bottom: 100%;
	}
}

.img-cover {
	background-size: cover;
}

.responsive-2by1 {
	&::before {
		padding-bottom: 50%;
	}
}

.responsive-3by2 {
	&::before {
		padding-bottom: 66.66667%;
	}
}

.responsive-4by3 {
	&::before {
		padding-bottom: 75%;
	}
}

.icon {
	font-family: $font-family-base !important;
	padding-left: 1.4rem;
	position: relative;
	
	&::before {
		@include icon-font;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
}

.file {
	display: flex;
	
	&__icon {
		font-size: 51px;
		color: $primary;
		line-height: 1;
	}
	
	&__desc {
		margin-left: .5rem;
	}
	
	&__title {
	}
	
	&__text {
		color: $secondary;
	}
}

.box-shadow {
	@include box-shadow;
}

.back-to-top {
	display: inline-block;
	border-radius: 5px;
	background-color: #E9EFF2;
	padding: 3rem .5rem .25rem;
	font-size: 13px;
	color: $body-color;
	text-align: center;
	position: fixed;
	right: 5vw;
	bottom: 5vh;
	z-index: -1;
	opacity: 0;
	transition: background-color $transition, opacity $transition;
	
	@include box-shadow;
	
	&::before {
		@include icon-font;
		content: $icon-dropdown;
		width: 32px;
		height: 32px;
		line-height: 34px;
		border-radius: 50%;
		background-color: $white;
		font-size: 1rem;
		position: absolute;
		top: .5rem;
		left: 50%;
		transform: translateX(-50%) scale(-1);
		transition: color $transition;
	}
	
	&:hover {
		background-color: $link-hover-color;
		color: currentColor;
	}
	
	&.shown {
		z-index: 1000;
		opacity: 1;
	}
}


// atoms
// .font-secondary {
// 	font-family: $font-family-secondary;
// }

.text-base {
	font-size: 1rem !important;
}

.text-big {
	font-size: 18px !important;
}

.text-small {
	font-size: 13px !important;
}

.note {
	font-size: 12px;
}

.line-height-1 {
	line-height: 1 !important;
}

.line-height-1_2 {
	line-height: 1.2 !important;
}

.mb-2rem {
	margin-bottom: 2rem !important;
}

.xs-full-width {
	@media (max-width: $screen-sm - 1) {
		width: 100%;
	}
}


/* Индикатор загрузки */
.loading-indicator {
	position: relative;
}

.loading-indicator .loading-layer,
.loading-indicator .loading-icon {
	position: absolute;
	z-index: 1000;
}

.loading-indicator .loading-layer {
	bottom: 0;
	left: 0;
	background-color: #fff;
	filter: alpha(opacity=50);
	opacity: 0.5;
	right: 0;
	top: 0;
}

.loading-indicator .loading-icon {
	height: 50px;
	margin: -25px 0 0 -40px;
	left: 50%;
	top: 50%;
	width: 80px;
}

body.loading-indicator .loading-icon {
	position: fixed;
}

.tooltip {
	.arrow {
		color: #ffffff;
	}
	
	.tooltip-inner {
		text-align: left;
		color: #111111;
		background-color: #ffffff;
		box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
	}
}

.text-grey {
	font-size: 13px;
	color: #808080;
}

.text-red {
	font-size: 13px;
	color: #ed2124;
}

.link-black {
	color: #111;
	
	&:hover {
		color: $link-hover-color;
	}
}

hr {
	opacity: 1;
	background-color: #004680;
	height: 2px;
}

.mb-n2 {
	margin-bottom: -0.5rem;
}

.mb-n3 {
	margin-bottom: -1rem;
}

.modal-header button.close {
	background-color: transparent;
	border: none;
	margin: 0;
	
}

.block-blue {
	border-radius: 8px;
	background-color: #6ECFFE;
}

.m-t-10 {
	margin-top: 10px !important;
}

ul.splide__pagination {
	margin: 0 -3px;
	padding: 0;
	width: 100%;
	
	li {
		padding: 0 3px;
	}
	
	& button {
		background-color: #999999;
		height: 3px;
		width: 25px;
		display: block;
		border-radius: 2px;
		border: none;
	}
	
	& button.is-active {
		background-color: #82D0F5;
	}
}

.splide__slide {
	transition: opacity !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	appearance: textfield !important;
}

input[type="number"] {
	-moz-appearance: textfield !important;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

.h1-pdf {
	font-size: 40px;
	font-weight: 700;
	color: #111;
}

.h2-pdf {
	font-size: 25px;
	font-weight: 700;
	color: #111;
	
}

.h3-pdf {
	font-size: 18px;
	font-weight: 700;
	color: #111;
}

.hr-pdf {
	border-bottom: 2px solid $primary;
}
.popover {
	width: calc(100vw - 30px);
	max-width: 260px;
	margin-left: -15px;
}